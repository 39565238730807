import React from 'react';
import logo from './logo.svg';
import './styles.scss';
import CountdownTimer from './targetDate';

function App() {
  const targetDate = new Date('2025-01-16T00:00:00Z');
  return (
<div className='dw-maintenance'>
         <video className='dw-maintenance-video' autoPlay muted loop>
    <source src="/dropworks.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
        <div className='dw-maintenance-child'>
    <div className="dw-maintenance-child-header"><img src="/Dropworks_logo.png"/></div>
    <div className='dw-maintenance-child-main'>
    <div className='dw-maintenance-child-main-content'>
        <div className='dw-maintenance-child-main-content-heading'>Coming Soon</div>
    </div>
    <div className='dw-maintenance-child-main-content2'>
    </div>

    <CountdownTimer  targetDate={targetDate} />
    </div>
      
        </div>
        
    </div>
  );
}

export default App;
